@import '@app/assets/css/text-style.css';
@import '@app/assets/css/border-style.css';

@tailwind base;
@tailwind components;
@tailwind utilities;

:root {
    --foreground-rgb: 0, 0, 0;
    --background-start-rgb: 214, 219, 220;
    --background-end-rgb: 255, 255, 255;
}

@media (prefers-color-scheme: dark) {
    :root {
        --foreground-rgb: 255, 255, 255;
        --background-start-rgb: 0, 0, 0;
        --background-end-rgb: 0, 0, 0;
    }
}

@font-face {
    font-family: 'Pretendard';
    src: url('/fonts/pretendard/PretendardStd-Thin.otf') format('opentype');
    font-weight: 100;
    font-style: normal;
}

@font-face {
    font-family: 'Pretendard';
    src: url('/fonts/pretendard/PretendardStd-Medium.otf') format('opentype');
    font-weight: 500;
    font-style: normal;
}

@font-face {
    font-family: 'Pretendard';
    src: url('/fonts/pretendard/PretendardStd-SemiBold.otf') format('opentype');
    font-weight: 600;
    font-style: normal;
}

@font-face {
    font-family: 'Pretendard';
    src: url('/fonts/pretendard/PretendardStd-Bold.otf') format('opentype');
    font-weight: 700;
    font-style: normal;
}

@font-face {
    font-family: 'Pretendard';
    src: url('/fonts/pretendard/PretendardStd-ExtraBold.otf') format('opentype');
    font-weight: 800;
    font-style: normal;
}

html,
body {
    padding: 0;
    margin: 0;
    font-family: 'Pretendard', sans-serif;
    height: 100vh;
    width: 100vw;
    overflow: hidden;
}

.flip {
    transform: scaleX(-1);
}

.uploadBoxShadow {
    box-shadow: 0px 0px 30px 0px rgba(0, 102, 219, 0.2);
}

::-webkit-scrollbar {
    width: 5px;
    height: 5px;
}

::-webkit-scrollbar-thumb {
    background-color: #888;
    border-radius: 10px;
    min-height: 40px;
}

.logsShadow {
    box-shadow: 0px 0px 30px 0px rgba(0, 102, 219, 0.2);
}

.\!h-tableHeight {
    height: calc(100vh - 240px + 32px - 116px) !important;
}

.base-Popper-root {
    z-index: 2600 !important;
}
.MuiPopover-root {
    z-index: 2700 !important;
}

.custom-scrollbar::-webkit-scrollbar {
    width: 10px;
}

.custom-scrollbar::-webkit-resizer {
    border-width: 8px !important;
    border-style: solid !important;
    border-color: transparent #1269cf #1269cf transparent !important;
}
